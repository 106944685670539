import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>Software Development</h3>
                            <p>We create software for your business need. Whether it is a CRM or Inventory management or any other dynamic site. We user ReactJS, NodeJS, AWS as our tech stack.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>Website Design</h3>

                            <p>We create a static site for your business. It can be a hotel service, personal website, startup page, bloggin etc. We use ReactJS or WordPress depending on user requirement.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>Offshore Team</h3>

                            <p>We provide skilled personal to handle your project. Contact us for software development, testing, project management or more.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>

                            </div>

                            <h3>Social Medial Marketing</h3>

                            <p>We provide a complete package to manage your social account. Contact us marketing your brand in Facebook, Instagram and other social media platforms. We also provide bulk email/sms service.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>Graphics Design</h3>

                            <p>We can create any design as you need which can range from social media banners, promotion graphic artifacts, or short video ads.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>Video Editing</h3>

                            <p>We provide a complete package to make promotional videos, movie for your footages, or just A-Z service in videography.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne
